import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tab.css';

class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = (evt) => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    handleKey = (e) => {
        if(e.key == 'Enter')
            this.onClick()
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
            },
        } = this;

        const isSchwab = window.location.pathname.indexOf('stax') > -1;

        // let className = 'tab-list-item no-select';

        // if (activeTab === label) {
        //     className += ' tab-list-active';
        // }

        let className = isSchwab ? 'tab-list-item-schw no-select': 'tab-list-item no-select';

        if (activeTab === label) {
            className += isSchwab ? ' tab-list-active-schw' : ' tab-list-active';
        }

        return (
            <>
                {isSchwab ?
                    <li id={'li-' + label}
                        className={className}
                        onClick={onClick}
                        onKeyDown={e => this.handleKey(e)}
                        role="tab"
                        tabIndex={0}
                    >
                        {label}
                    </li> :
                    <li id={'li-' + label}
                        className={className}
                        onClick={onClick}
                        role="tab"
                        tabIndex={0}
                        data-sitevendortrack={'{"actionName":"chartClick","details":["table;' + label + '"]}'}
                    ><div className={this.props.iconClass}></div>
                        {label}
                    </li>
                }
            </>
        );
    }
}


export default Tab;