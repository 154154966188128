import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from './App';
import PageNotFound from './PageNotFound';
import AppSchw from './AppSchw';

function Home () {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={() => {
                    window.location.href = 'https://www.schwab.com/investment-research/stax/view-schwab-trading-activity-index';
                    return null;
                }} />
                {/* <Route path='/' exact={true} component={AppSchw}/> */}
                <Route exact path="/imx/p/imx-pub/" component={() => {
                    window.location.href = 'https://www.schwab.com/investment-research/stax/view-schwab-trading-activity-index';
                    return null;
                }} />
                {/* <Route path='/imx/p/imx-pub/' exact={true} component={App}/> */}
                <Route path='/imx/p/imx/' exact={true} component={App}/>
                <Route path='/imx/p/stax/' exact={true} component={AppSchw}/>
                <Route path='*' component={PageNotFound}/>
            </Switch>
        </BrowserRouter>
    );
}

export default Home;
