import React from 'react';
import AboutIMX from '../components/./AboutIMX';
import BreadCrumbs from '../components/./BreadCrumbs';
import Charts from '../components/Charts';
import Footer from '../components/./Footer';
import { formatMonthDate } from '../components/./utilities';
import Header from '../components/./Header';
import Media from '../components/./Media';
import SummaryIMX from '../components/./SummaryIMX';
import Tabs from '../components/./Tabs';
import {fetch as fetchPolyfill} from 'whatwg-fetch';



export default class extends React.Component {
    constructor(props) {
        super(props);

        this.updateMonthDate = this.updateMonthDate.bind(this);

        this.state = {
            drop:0,
            currentValue:0,
            articles: [],
            summary: [],
            readingData: {},
            monthDate: '',
            chartInfo:'',
            video:{},
            dataPoints: [],
            isLoading: false,
            hasError: false
        };
    }

    updateMonthDate = (value, eventClick="chart table") => {
        this.setState({currentValue:value});
        this.setState({isLoading: true});

        fetchPolyfill(this.props.api + '/imxData/' + formatMonthDate(this.state.dataPoints[value].date, 'mmddyyyy'), {
            method: 'get',
            headers: this.props.apiKey ? {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'apikey': this.props.apiKey
            } : {}
        })
            .then(res => {
                this.setState({isLoading: false});
                if(res.status !== 200) {
                    this.setState({hasError: true});
                }
                return res.json()
            })
            .then(json => {
               // console.log(json);
                this.setState({
                    drop:this.state.dataPoints[value].summaryIndex,
                    articles: json.imxArticles,
                    summary: json.summary,
                    readingData: json,
                    video:{ videoCaption:json.videoCaption, videoFile:json.videoFile, videoPoster:json.videoPoster },
                    chartInfo: formatChartInfo(json),
                    monthDate: formatMonthDate(this.state.dataPoints[value].date)
                });

                if (window.TDAG && window.TDAG.analytics) {

                    window.TDAG.analytics.track({

                        actionName : "eventClick",

                        details : ["summary " + eventClick + ": " + this.state.monthDate.toString()]

                    });

                }

                //Highlight on tables///////////////////////////////////
                const rows = document.getElementsByClassName("row-info");
                for(var i = 0; i < rows.length; i++) {
                    rows[i].classList.remove("item-active");
                    if( rows[i].dataset.date===this.state.monthDate){
                        rows[i].classList.add("item-active");
                    }
                }

            });




    }



    componentDidMount() {
        let summaryIndex=0;
        this.setState({isLoading: true});
        fetchPolyfill(this.props.api + '/chartData', {
            method: 'get',
            headers: this.props.apiKey ? {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'apikey': this.props.apiKey
            } : {}
        })
        .then(res => {
            if(res.status == 200) {
                this.setState({
                    isLoading: false,
                    hasError: false
                });
            }
            return res.json()
        })
            .then(json => {
                this.setState({
                    monthDate: formatMonthDate(json[json.length-1].date),
                    dataPoints: json.reverse().map( (obj,i) =>  {
                        //(obj.hasSummary)? (obj.summaryIndex=summaryIndex, summaryIndex++):null;
                        if(obj.hasSummary){obj.summaryIndex=summaryIndex; summaryIndex++;};
                        obj.year= obj.date.slice(-4);
                        obj.val=i;
                        return(obj)} )
                });

                if(this.state.dataPoints){
                    this.updateMonthDate(0);
                }

            }).catch(error => {
                this.setState({isLoading: false});
                this.setState({hasError: true});
        });
    }

    render() {
        if(!this.state.isLoading && !this.state.hasError){
            return showIMX(this.props, this.state, this.updateMonthDate);
        }else if (this.state.isLoading){
            return <div class="page-loader">
                <img src={require('.././img/spinner.svg')} alt="Loading"/>
            </div>
        } else {
            return showUnavailableMessage();
        }
    }
}
//todo make this a component
const formatChartInfo  = (dayData) => {
    const imxUp = (dayData.imxScoreChange >= 0)? 'trend-up':'trend-down';
    const spxUp = (dayData.spxScoreChange >= 0)? 'trend-up':'trend-down';
    return(
        <div id ={'tabs-header'}>


            <div id ={'title-spx'} className={'txt-Helvetica13 float-r'}>
                <div className={'chart-info-box '}>SPX | {dayData.spxScore }</div>
                <div className={'chart-info-box ' +spxUp}><span className={(spxUp==="trend-down")? "img-arrow-down-sm": "img-arrow-up-sm"}></span>{dayData.spxScoreChange.toFixed(2)} ({(spxUp==="trend-down")? null: "+"}{dayData.spxScoreChangePercent.toFixed(2)}%)</div>
            </div>

            <div id ={'title-imx'} className={'txt-Helvetica13 float-r'}>
                <div className={'chart-info-box '}>IMX | {dayData.imxScore }</div>
                <div className={'chart-info-box ' +imxUp}><span className={(imxUp==="trend-down")? "img-arrow-down-sm": "img-arrow-up-sm"}></span>{dayData.imxScoreChange.toFixed(2)} ({(imxUp==="trend-down")? null: "+"}{dayData.imxScoreChangePercent.toFixed(2)}%)</div>
            </div>


        </div>
    );

};

function showIMX(props, state, updateMonthDate){

    return (

        <div>
            {
                (props.public)
                    ? <BreadCrumbs monthDate={state.monthDate} />
                    : null
            }

            <Header
                monthDate={state.monthDate}
                isPrint={props.isPrint}
                printHandler={props.printHandler}
            />


            <Tabs label={'monthly'}>
                <div label="Monthly Summary">

                    <div className={"bm-30"}></div>
                    <div id={'txt-header'} className={"txt-Helvetica20 border-bottom bm-20"}>IMX vs S&P 500</div>
                    <p id={'txt-container'} className={"txt-Helvetica12 bm-20"}>The Investor Movement Index, or the IMX, is a proprietary, behavior-based index created by TD Ameritrade designed to indicate the sentiment of retail investors.</p>

                    <div id={'myChart'}></div>

                    <Charts
                        chartInfo={state.chartInfo}
                        currentValue={state.currentValue}
                        dataPoints={state.dataPoints}
                        handleChange={updateMonthDate}
                        isPrint={props.isPrint} 
                        monthDate={state.monthDate}
                    />


                    <SummaryIMX
                        drop={state.drop}
                        monthDate={state.monthDate}
                        summary={state.summary}
                        dataPoints={state.dataPoints}
                        readingData={state.readingData}
                        video={state.video}
                        handleChange={updateMonthDate}
                        isPrint={props.isPrint}
                        public={props.public}
                    />

                    <Footer footer={props.footer}/>

                    <Media articles={state.articles} toggleModal={props.toggleModal} public={props.public}/>

                </div>

                <div label="About the IMX">

                    <AboutIMX api={props.api}
                              apiKey={props.apiKey}  />

                </div>

            </Tabs>



        </div>

    );
}

function showUnavailableMessage() {
    return (
        <div>

            <h2 style={{paddingTop: "75px"}}>IMX page is temporarily unavailable, please try again later.</h2>
        </div>

    );
}