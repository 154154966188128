import React, { Component } from 'react';
import './SubscribeToIMX.css';
import AlreadySubscribed from './AlreadySubscribed';
import SubscriptionConfirmed from './SubscriptionConfirmed';
import {fetch as fetchPolyfill} from 'whatwg-fetch';
import SimpleCaptcha from "./SimpleCaptcha";
import {loadCaptchaEnginge, validateCaptcha} from "react-simple-captcha";


class SubscribeToIMX extends Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.captchaMessage = "First Message";
        this.state = {
            captureMessage: "",
            validCaptcha:true
        }
    }


    captchaValidate()  {
        {
            //let user_captcha = document.getElementById("user_captcha_input").value;

            if (validateCaptcha(this.state.captureMessage) == true) {
                this.setState({validCaptcha:true})
                //this.setState({ captchaMessage: "Captcha Matched"}) ;
                loadCaptchaEnginge(6);
                document.getElementById("user_captcha_input").value = "";
                return true;
            } else {
                this.setState({validCaptcha:false})
                //this.setState({ captchaMessage: "Captcha Does not Match"}) ;
                document.getElementById("user_captcha_input").value = "";
                return false;
            }
        }
    }


    handleChange = (captchaMessage) => {
        //let user_captcha = document.getElementById("user_captcha_input").value;

        this.setState({
            captureMessage:captchaMessage
        })
    }

    handleSubmit(evt) {

        evt.preventDefault();

        let captchaValid = this.captchaValidate();

        if(!captchaValid){
            return;
        }
        //const formData = new FormData(evt.target);
       let jsonObject = {
           firstName:document.getElementById("firstName").value,
           lastName:document.getElementById("lastName").value,
           email:document.getElementById("email").value,
           isTdaCustomer:"true"

       };
       if(document.getElementById("notTDA")){
           if(document.getElementById("notTDA").value){
               jsonObject.isTdaCustomer='false'
           }
       }


       // for (const [key, value]  of formData.entries()) {
       //     jsonObject[key] = value;
       // }
       //"firstName":"a","lastName":"a","email":"a@a.com","isTdaCustomer":"true"
       // console.log(JSON.stringify(jsonObject))
       fetchPolyfill(this.props.api + '/subscribe', {
           method: 'post',
           headers: this.props.apiKey ? {
               'Accept': 'application/json, text/plain, */*',
               'Content-Type': 'application/json',
               'apikey': this.props.apiKey
           } : {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
           body: JSON.stringify(jsonObject)
       })
           //.then(res => res.json())
           .then(res=>res.status)
           .then(json => {
               console.log(json);
               switch(json) {
                   case 201:
                       this.props.toggleModal();
                       this.props.toggleModal(<SubscriptionConfirmed toggleModal={this.props.toggleModal}  public={this.props.public}/>);
                       break;
                   case 400:
                       console.log("Bad Request");
                       break;
                   case 409:
                       //console.log("Conflict");

                       this.props.toggleModal();
                       this.props.toggleModal(<AlreadySubscribed toggleModal={this.props.toggleModal}  public={this.props.public}/>);
                       break;
                   case 500:
                       console.log("Failure");
                       break;
                   default:
                       console.log(json);
               }


           });

    }

    render(){
        console.log(this.props.api)
        return (
            <div className={'subscribe-main-container'}>
                <div className={'subscribe-content-container'}>
                    <div className={"txt-Helvetica20 border-bottom bm-30"}>Subscribe to IMX</div>
                    <p>Enter your information to receive an email notification when the latest monthly results are released.</p>
                    <p>* All fields are required.</p>

                    <form onSubmit={this.handleSubmit}>


                        <div className={'subscribe-flex-container bm-20'}>

                            <div className={'subscribe-input-container bm-20'}>
                                <div className={'txt-Helvetica14' }>First name *</div>
                                <input id="firstName" type="text" name='firstName' required pattern="^[A-Za-z0-9-\s_.,':/\\()#]*$"/>
                                <div className={'txt-Helvetica16 subscribe-error-txt none'}>You must fill in this field.</div>
                            </div>


                            <div className={'subscribe-input-container bm-20'}>
                                <div className={'txt-Helvetica14'}>Last name *</div>
                                <input id='lastName' type="text" name='lastName' required pattern="^[A-Za-z0-9-\s_.,':/\\()#]*$"/>
                                <div className={'txt-Helvetica16 subscribe-error-txt none'}>You must fill in this field.</div>
                            </div>

                            <div className={'subscribe-input-container bm-20'}>
                                <div className={'txt-Helvetica14'}>Email address *</div>
                                <input id='email' type="email" name='email' required/>
                                <div className={'txt-Helvetica16 subscribe-error-txt none'}>You must fill in this field.</div>
                            </div>

                        </div>

                        {(this.props.public)?
                            <div>
                                <div className={'txt-Helvetica14 bm-10'}>Do you have a TD Ameritrade account? *</div>
                                <input className={'subscribe-radio-input'} type="radio" name='isTdaCustomer' value={true} required/><label className="txt-Helvetica14 subscribe-radio subscribe-label">Yes</label>
                                <input id='notTDA' className={'subscribe-radio-input'} type="radio" name='isTdaCustomer' value={false} required/><label className="txt-Helvetica14  subscribe-label">No</label>
                            </div>

                            :
                            <div className={'none'}>
                                <input className={'subscribe-radio-input'} type="radio" name='isTdaCustomer' value={true} defaultChecked="checked"/><label className="txt-Helvetica14 subscribe-radio subscribe-label">Yes</label>
                            </div>
                        }



                        <p className={'bm-20'}>Please note: This does not change the primary email address for your account.</p>



                        <div className="subscribe-captcha">
                            <SimpleCaptcha  onChange={event => this.handleChange(event.currentTarget.value.trim())} validCaptcha={this.state.validCaptcha}/>
                        </div>

                        <div className={'subscribe-flex-container'}>

                            <div className={'subscribe-input-container'}>
                                <button className={'btn no-select'} data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["subscribe"]}'>Subscribe to IMX</button>
                            </div>
                            <div className={'subscribe-input-container'}>
                                <div className={'subscribe-cancel-link no-select'} onClick={() => {this.props.toggleModal()}}>cancel</div>
                            </div>

                        </div>



                    </form>



                </div>

            </div>
        );
    }


}

export default SubscribeToIMX;