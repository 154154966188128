import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

class Modal extends Component {

    handleKey = (e) => {
        if(e.key == 'Escape')
            this.props.onClose();
    }

    render() {
        // Render nothing if the "show" prop is false
        if(!this.props.show) {
            return null;
        }

        return (
            <div className={'backdrop'}>
                <div>
                    <div className={'modal-container'} style={this.props.style} role={'dialog'} aria-modal={"true"}>
                        {/*<div className="modal-content-container">*/}
                            <div className={'close-container'}>
                                <a id="modal-close" href="javascript:void(0);" onKeyDown={e => this.handleKey(e)} onClick={this.props.onClose}><div className={'img-close modal-icon' }></div></a>
                            </div>
                            {this.props.content}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node
};

export default Modal;