
const localStore = {
    setItem: (item, value) => localStorage.setItem(`imx-web-${item}`, value),
    getItem: (item) => localStorage.getItem(`imx-web-${item}`),
    clearAll: () => {
        localStorage.clear();
    }
};

const sessionStore = {
    setItem: (item, value) => sessionStorage.setItem(`imx-web-${item}`, value),
    getItem: (item) => sessionStorage.getItem(`imx-web-${item}`),
    clearAll: () => {
        sessionStorage.clear();
    }
};

const configManager = {
    setConfig: (configData) => sessionStore.setItem('configData', configData),
    getConfig: () => sessionStore.getItem('configData')
}

export { sessionStore, configManager };
