import { fetch as fetchPolyfill } from 'whatwg-fetch';
import React, { Component } from 'react';
import './AppSchw.css';
import ContainerIMXSchw from './ContainerIMXSchw';
import Modal from '../components/Modal';
import axios from 'axios';
import { getCookie } from '../utils/getCookie';
import * as pym from 'pym.js';
import { configManager } from '../utils/localStoreManager';
import SkeletonLoader from '../utils/SkeletonLoader';


class AppSchw extends Component {

    constructor() {
        super();

        this.state = {
            isOpen: false,
            isPrint: false,
            modalChildren: '',
            modalStyle: {},
            notification: '',
            experts: [],
            contactUs: '',
            footer: '',
            public: true,
            isLoading: true,
            api: window.env.API_URL,
            apiKey: '',
            statusCode: ''
        };
    }



    toggleModal = (children, style) => {
        this.setState({
            isOpen: !this.state.isOpen,
            modalChildren: children,
            modalStyle: style
        });
    }

    printBefore = () => {

        document.getElementById('chartHighlight').style.display = 'none';
        document.getElementById('chartBorder').style.display = 'none';

    }

    printAfter = () => {

        document.getElementById('chartHighlight').style.display = 'block';
        document.getElementById('chartBorder').style.display = 'block';


    }

    retrieveConfig = () => {
        axios.get('/retrieve_config', {
            headers: {
                'csrf-token': getCookie('CSRF-TOKEN')
            },
            withCredentials: true
        }).then(response => {
            const data = response && response.data !== undefined && response.data !== '' ? response.data : window.env.API_HEADER;
            console.log(data);
            this.setState({
                apiKey: data
            })
            configManager.setConfig(data);
        }).catch(error => {
            this.setState({ isLoading: false });
        });
    };

    togglePrint = () => {
        this.printBefore();
        setTimeout(function () {
            window.onbeforeprint = () => {

                this.setState({
                    isPrint: true
                });
            };
            window.onafterprint = () => {

                this.setState({
                    isPrint: false
                });
            };
            window.print();
            this.printAfter();
        }.bind(this), 1);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.apiKey !== this.state.apiKey && this.state.apiKey != '') {
            this.setState({ isLoading: false });
            const scriptIMX = document.createElement('script');
            const headerFooterPath = window.env.HEADER_FOOTER_PATH;
            scriptIMX.src = window.env.STOCK_URL;
            document.getElementsByTagName('head')[0].appendChild(scriptIMX);


            fetchPolyfill(this.state.api + '/static', {
                method: 'get',
                headers: this.state.apiKey ? {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'apikey': this.state.apiKey
                } : {}
            })
                .then(res => {
                    this.setState({
                        statusCode: res.status
                    });
                    return res.json()
                })
                .then(json => {
                    this.setState({
                        footer: json.footer,
                        notification: json.notification,
                        experts: json.experts,
                        contactUs: json.contactUs
                    });
                });

            if (window.location.href.indexOf("pub") > -1 || window.location.pathname === "/") {
                this.setState({
                    public: true
                });
            } else {
                this.setState({
                    public: false
                });
            }
        }
    }

    componentDidMount() {
        const pymChild = new pym.Child({
            id: window.location.hash.substr(1),
            polling: 400
        });
        pymChild.sendHeight();
        pymChild.sendMessage('childLoaded', 'ready');

        const configData = configManager.getConfig();
        if (!configData || configData === '')
            this.retrieveConfig();
        else {
            axios.defaults.headers.common['apikey'] = configData;
            this.setState({
                apiKey: configData,
                isLoading: false
            });
        }
    }

    render() {

        return (
            <div className="app-schw">
                {this.state.isLoading && <SkeletonLoader className="margin-top margin-bottom" />}
                {!this.state.isLoading &&
                    <>
                        <div className={'main-container-schw'}>
                            <ContainerIMXSchw
                                toggleModal={this.toggleModal}
                                footer={this.state.footer}
                                public={this.state.public}
                                isPrint={this.state.isPrint}
                                printHandler={this.togglePrint}
                                api={this.state.api}
                                apiKey={this.state.apiKey}
                                notification={this.state.notification}
                                experts={this.state.experts}
                                contactUs={this.state.contactUs}
                                public={this.state.public}
                            />
                        </div>


                        <Modal
                            show={this.state.isOpen}
                            onClose={this.toggleModal}
                            content={this.state.modalChildren}
                            style={this.state.modalStyle}
                        />
                    </>
                }

            </div>
        );
    }
}

export default AppSchw;
