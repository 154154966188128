import React from 'react';
import './Footer.css';

export default function Footer(props) {

    return (
        <div id={'imx-footer'} className={"footer-container txt-footer bm-30"} dangerouslySetInnerHTML={{__html:props.footer}}>

        </div>
    );
}