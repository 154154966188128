import React from 'react';
import ReadingIMXSchw from './ReadingIMXSchw';
import Symbols from './Symbols';
import { formatMonthDate } from './utilities';
import Video from './Video';
import './SummaryIMXSchw.css';
import Parser from 'html-react-parser';
import DropdownSchw from './DropdownSchw';

class SummaryIMXSchw extends React.Component {


    render() {
        let cName = sessionStorage.getItem("cName") || "investsdev07__VENDOR";
        const adobeClick = () => {
            try {
                const tagMessage = {
                    "eventType": "trackEvent",
                    "tagString": "adobe-lc_ticker_link"
                };
                window.parent.postMessage(tagMessage, '*');
                console.log(postMessage);
            } catch (err) {
                console.log('iFrame Analytics Handler Error - Inner, GUT, triggering call: ' + err.message);
            }
        }
        const parseOptions = {
            replace: (domNode) => {
                // do not replace if element has no attributes
                if (!domNode.attribs) return;

                if (domNode.attribs.class === 'symlink-schw') {

                    if (this.props.public) {
                        return (
                            <a
                                className={domNode.attribs.class}
                                target='_blank'
                                href={domNode.attribs.href}
                                onClick={adobeClick}
                            >
                                {domNode.attribs.symbol}
                            </a>
                        );

                    } else {

                        return (
                            <a
                                className={domNode.attribs.class}
                                target='_blank'
                                href={domNode.attribs.href}
                                onClick={adobeClick}
                            >
                                {domNode.attribs.symbol}
                            </a>
                        );
                    }


                }
            }
        };

        let mySummary = this.props.summary.toString().replace(/#,\s*#/g, '#),(#');
        let symbolIndex = 0;
        const aSymbols = [];
        const aSummary = this.props.dataPoints.filter((dataPoint) => dataPoint.hasSummary);
        let minDataPoint = {};
        let maxDataPoint = {};
        if (this.props.dataPoints && this.props.dataPoints.length > 0) {
            let minScore = Math.min.apply(null, this.props.dataPoints.map(function (item) {
                return item.imxScore;
            }));
            let maxScore = Math.max.apply(null, this.props.dataPoints.map(function (item) {
                return item.imxScore;
            }));
            let minEntries = this.props.dataPoints.filter(val => val.imxScore == minScore);
            let maxEntries = this.props.dataPoints.filter(val => val.imxScore == maxScore);
            minDataPoint = minEntries[0];
            maxDataPoint = maxEntries[0];
        }

        const dropDownProps = aSummary.map((dataPoint) => {
            const val = formatMonthDate(dataPoint.date);
            return ({ label: val, val: dataPoint.val, hasVideo: dataPoint.hasVideo });
        });

        mySummary.replace(/\(##(.*?)##\)/g, function (match) {
            aSymbols.push(match.split('(##')[1].split("##)")[0]);
        });
        //TODO data to symbol for tos popup
        //<a className="symlink" href="http://research.tdameritrade.com/grid/public/stocks/overview/overview.asp?symbol=WMT&amp;fromPage=overview&amp;display=&amp;fromSearch=true" data-tda-symbol="{&quot;symbol&quot;:&quot;WMT&quot;,&quot;securityType&quot;:&quot;E&quot;}" data-sitevendortrack="{&quot;actionName&quot;:&quot;eventClick&quot;,&quot;details&quot;:[&quot;summary:symbol click&quot;]}">WMT</a>
        //non pub
        //



        //This <a> gets replaced in parserOptions
        mySummary = mySummary
            .replace(/\(##/g, (x) => {
                const mySymbol = aSymbols[symbolIndex];
                const myHref = "https://www.schwab.com/research/stocks/quotes/summary/" + aSymbols[symbolIndex];

                symbolIndex++;
                return ('<strong>(<a class="symlink-schw" target=_blank href=' + myHref + ' symbol=' + mySymbol + '>');
            }).replace(/##\)/g, '</a>)</strong>');



        return (
            <div id={'content-split-container-schw'} className={"content-split-container-schw"}>


                {(this.props.video.videoFile && !this.props.isPrint) ? <Video video={this.props.video}></Video> : null}


                <div className={"mb-view"}>
                    <div id={'select-month'} className={'select-container bm-20 lm-15'}>



                        <DropdownSchw options={dropDownProps} handleChange={this.props.handleChange} drop={this.props.drop} monthDate={this.props.monthDate} />

                    </div>
                    <div id={'contect-right'} className={"content-right-container-schw float-r"}>
                        <ReadingIMXSchw monthDate={this.props.monthDate} readingData={this.props.readingData} minDataPoint={minDataPoint} maxDataPoint={maxDataPoint}></ReadingIMXSchw>
                    </div>
                </div>
                <div id={'main-content'} className={"content-left-container-schw txt-Helvetica12-schw bm-30"}>

                    <div className={"desktop-view"}>
                        <div id={'select-month'} className={'select-container bm-20 lm-15'}>



                            <DropdownSchw options={dropDownProps} handleChange={this.props.handleChange} drop={this.props.drop} monthDate={this.props.monthDate} />

                        </div>
                        <div id={'contect-right'} className={"content-right-container-schw float-r"}>
                            <ReadingIMXSchw monthDate={this.props.monthDate} readingData={this.props.readingData} minDataPoint={minDataPoint} maxDataPoint={maxDataPoint}></ReadingIMXSchw>
                        </div>
                    </div>



                    <div id={'summary-style'} className={'summary-style'}>
                        <div className={"txt-Helvetica24-schw bm-20"} style={{ lineHeight: '28px' }}>

                            <span>Schwab Trading Activity Index Summary:</span>
                            <span style={{ paddingLeft: "4px" }}>{this.props.monthDate.toString()}</span>

                        </div>
                        {Parser(mySummary, parseOptions)}
                    </div>

                </div>
            </div>
        );
    }
}

export default SummaryIMXSchw;


function removeDuplicate(arr) {
    let unique_array = arr.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
    });
    return unique_array
}