import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';

import "./Dropdown.css";

class Dropdown extends React.Component {

  static propTypes = {
    options: PropTypes.array.isRequired,
    block: PropTypes.bool,
    handleChange: PropTypes.func
  };

  static defaultProps = {
    block: false
  };


  render() {

    const { options, block, handleChange, ...remainingProps } = this.props;

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            fontSize:'14px',
            fontWeight: 'normal',
            color:'black',


        }),
        option: (provided, state) => ({
            ...provided,
            //height:'20px',
            padding:'0 10px',
            color: state.isSelected ? 'black' : 'black',
            backgroundColor:'white',
            "&:hover": {
                color:'red',
                backgroundColor:'green'
            }

        }),
        placeholder: (provided, state) => ({
            ...provided,
            color:'black',
        }),
        menu: (provided, state) => ({
            ...provided,
            marginTop:0,
        }),
        menuList: (provided, state) => ({
            ...provided,
            height:'200px'
        }),
        control: (base, state) => ({
            ...base,
            color:'black',
            borderColor:'grey',
            //border: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                //border: state.isFocused ? 0 : 0
            }
        })

    }
    return (

        <Select
            name={this.props.drop}
            value={this.props.drop}
            placeholder={this.props.monthDate.toString()}
            onChange={e => {
                handleChange(e.val, "dropdown");
            }}

            styles={customStyles}
            options={options}
        />
      // Browser default dropdown select
      // <select
      //     onMouseDown={ function(){this.size='5';}.bind(this) }
      //     value={this.props.drop}
      //     className={`${'container'} ${block ? "block" : ""}`}
      //
      //   onChange={e => {
      //     handleChange(e.target.value);
      //   }}
      //   {...remainingProps}
      // >
      //   {options.map((o, i) => (
      //
      //     <option value={o.val} key={`dropdown_${i}`}>
      //         { (o.hasVideo)?o.label + " " :o.label }
      //     </option>
      //   ))}
      // </select>
    );
  }
}

export default Dropdown;
