const isEmpty = (value) => {
    const valueType = typeof value;
    let isValueEmpty = false;

    switch (valueType) {
        case 'string':
            isValueEmpty = String(value).trim().length === 0;
            break;

        case 'number':
            isValueEmpty = value < 0;
            break;

        case 'boolean':
            isValueEmpty = false;
            break;

        default:
            isValueEmpty = !value;
            break;
    }
    return isValueEmpty;

};

const getCookie = (name) => {
    if (isEmpty(name)) {
        return '';
    }

    const nameLenghtPlus1 = (name.length + 1);

    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenghtPlus1) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenghtPlus1));
        })[0] || '';
}

export { getCookie };