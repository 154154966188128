import React, { Component } from 'react';
import ChartTableSchw from './ChartTableSchw';

class ChartTablesSchw extends Component {

    constructor(props){
        super(props)
        this.updateTables = this.updateTables.bind(this);

        this.state = {
            showAll:false
        };
    };

    updateTables = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    render() {

        const oGroup=groupBy(this.props.dataPoints,'year');
        const aSortGroup=[];


        function groupBy(arr, property) {
            return arr.reduce(function(memo, x) {
                if (!memo[x[property]]) { memo[x[property]] = []; }
                memo[x[property]].push(x);
                return memo;
            }, {});

        }

        for (var key in oGroup) {
            if (oGroup.hasOwnProperty(key)) {
                aSortGroup.push(key);
            }
        }

        aSortGroup.sort((a, b) => b-a);

        let aYearGroups = aSortGroup.map( (year) => {
            if( oGroup[year].length < 12){
                for (let j = oGroup[year].length+1; j <= 12; j++) {
                    oGroup[year].unshift({"date": "---", "spxScore":"---", "imxScore":"---"})
                }
            };
            return(<ChartTableSchw key={'table-'+year} year={year} yearData={oGroup[year]} handleChange={this.props.handleChange} monthDate={this.props.monthDate}/>);
        });


        return (
            <div>
                <div id={'chart-tables'} className={'chart-tables'}>
                    {(this.state.showAll)? aYearGroups : [aYearGroups[0], aYearGroups[1]]}
                </div>



                <div className={'chart-footer-container'}>
                    <div className={'float-l'} style={{width: "50%" }}>
                        To see a different month, click a data point in the chart.
                    </div>
                    <div className={'chart-footer float-l'}  style={{width: "70%" }}>
                        The STAX begins incorporating Schwab client data as of January 2019.
                    </div>
                    <div className={'float-r'} onClick={this.updateTables}>
                        <a className={'chart-link'} href="#myChart">{(this.state.showAll)? 'Hide previous years.' : 'Show previous years.'}</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChartTablesSchw;