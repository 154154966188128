import React, { Component } from 'react';
import './Tooltip.css';

class Tooltip extends Component {
    render() {
        // Render nothing if the "show" prop is false
        if(!this.props.showTooltip) {
            return null;
        }

        return (
            <div className={'tooltip'} style={{top : this.props.y-62, left: this.props.x-16}}>
                <div className={'tooltip-content-container'}>
                    <div className={'img-chart-hover icon-chart-hover'}><div className={'txt-tooltip'}><strong>{this.props.dataPointValue}</strong></div></div>
                </div>

            </div>
        );
    }
}


// Tooltip.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     show: PropTypes.bool,
//     children: PropTypes.node
// };

export default Tooltip;
