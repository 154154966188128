import React from 'react';

export default class extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <div>
                <p>Page you are looking for, is not present. Please check the URL.</p>
            </div>

        );
    }
}