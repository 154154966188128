import React from 'react';
import './BreadCrumb.css';

export default function BreadCrumbs(props) {

    return (
        <div className="breadcrumb-main-container" >
            <strong>
                <a className="breadcrumb" href="https://www.tdameritrade.com/home.page">Home</a>
                <span className="breadcrumb"> &gt;</span>
                <a className="breadcrumb" href="https://www.tdameritrade.com/investment-research.page">Research</a>
                <span className="breadcrumb"> &gt;</span>
                <span className="breadcrumb"> Investor Movement Index</span>
                <span className="breadcrumb"> &gt;</span>
                <span className="breadcrumb">{props.monthDate.toString()}</span>
            </strong>
        </div>
    );
}