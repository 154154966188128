import React from 'react';
import RightRail from '../components/./RightRail';

export default class extends React.Component {

    render() {
        return (

            <div>
                <RightRail
                    toggleModal={this.props.toggleModal}
                    notification={this.props.notification}
                    contactUs={this.props.contactUs}
                    public={this.props.public}
                    api={this.props.api}
                    apiKey={this.props.apiKey}
                />
            </div>

        );
    }
}