import React, {Component} from 'react';
import './AboutIMX.css';
import {fetch as fetchPolyfill} from 'whatwg-fetch';

class AboutIMX extends Component {
    constructor(props) {
        super(props);

        this.state = {
            qaData:[]
        };
    }

    componentDidMount() {

        fetchPolyfill(this.props.api + '/faqs', {
            method: 'get',
            headers: this.props.apiKey ? {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'apikey': this.props.apiKey
            } : {}
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    qaData:json.map((obj,i) =>{
                       return(
                           <div id={'qa-'+i} key={'qa-'+i}>
                               <div  className={"txt-Helvetica16 border-bottom about-header"}>{obj.question}</div>

                               {/*TODO set a html parser*/}
                               <div  dangerouslySetInnerHTML={ {__html : obj.answer} }></div>
                           </div>

                       );
                    }),
                });
            });
    }

        render(){
            return (
                <div className={"content-about-container"}>
                    {this.state.qaData}
                    <div>
                        <a className="green-link float-r" href="#back">Back to top</a>
                    </div>
                </div>

            );

        }

}

export default AboutIMX;