import React from 'react';
import SubscribeToIMX from './SubscribeToIMX'
import './RightRail.css';
export default function RightRail(props) {

    return (
            <div>

                <div className={"txt-Helvetica16 border-bottom bm-20"}>Subscribe</div>
                <div className={"txt-Helvetica12 bm-10"}>{props.notification}</div>

                <div id={'btn-subscribe'}><a className={"btn bm-20"} target={'_blank'} onClick={() => {
                    props.toggleModal(<SubscribeToIMX toggleModal={props.toggleModal } public={props.public} api={props.api} apiKey={props.apiKey}/>)
                }}>Subscribe to IMX</a></div>

                <div className={"txt-Helvetica16 border-bottom cb bm-10"}>Contact Us</div>

                {(props.public) ?
                    <div className={"bm-10"}>
                        <a  href="https://www.tdameritrade.com/service.page" data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["customer service"]}'  className={'right-rail-customer-service'}>TD Ameritrade Customer Service</a>
                    </div>
                    :
                    <div className={"bm-10"}>
                        <div>
                            <div className={"img-phone icon-16"}></div>
                            Call {props.contactUs.phone}
                        </div>
                    </div>
                }

                {(props.public) ?
                    <div className={"bm-20"}>
                        <div className={"tool-link"}>
                            <a  href={props.contactUs.emailPub} data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["email"]}'>
                                <div className={"img-email icon-16"}></div>
                                <span>Email</span>
                            </a>
                        </div>
                    </div>
                    :
                    <div className={"bm-20"}>
                        <div className={"tool-link"}>
                            <a href={props.contactUs.emailSec} data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["email"]}'>
                                <div className={"img-email icon-16"}></div>
                                <span>Email</span>
                            </a>
                        </div>
                    </div>
                }


                <div className={"txt-Helvetica16 border-bottom bm-10"}>Find a Branch</div>

                <form action={'https://www.tdameritrade.com/branch-locator.page?'} target={'_blank'} method={'get'}
                      encType={"multipart/form-data"}>
                    <input id={'txtaddress'} className={"float-l bm-20"} placeholder=" City, State, or Zip" name={"address"} type="text" encType={"multipart/form-data"}/>
                    <input className={"none"} placeholder="Radius" name={"radius"} value={'100'} type="text" onChange={()=>{}} encType={"multipart/form-data"}/>
                    <button id={'btnlocator'} className={'btn btn-small float-l'}   data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["locator"]}'>GO</button>
                </form>

                {(props.public)?
                <div>
                    <div className={"txt-Helvetica16 border-bottom cb bm-10"}>Media Inquiries</div>

                    <div className={"bm-10"}>

                        <div className={"bm-10"}>
                            <a href="http://www.amtd.com/newsroom/default.aspx" data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["newsroom"]}' className={'right-rail-customer-service'}>AMTD Newsroom </a>
                        </div>

                        <div className={"bm-10"}>
                            <span>{props.contactUs.mediaInquiryPhone}</span>
                        </div>

                    </div>
                </div>
                    :
                    null
                }

            </div>

    );
}








