import React, { Component } from 'react';
import SimpleShare from './SimpleShare';
import ShareWithFriends from './ShareWithFriends';
import './Header.css';

class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShareOpen:false,
            isFriendsOpen:false
        }

    }

    shareToggle = () => {

        this.setState({
            isShareOpen:!this.state.isShareOpen
        })

    }

    friendsToggle = () => {

        this.setState({
            isFriendsOpen:!this.state.isFriendsOpen
        })

    }



    render(){

        return (
            <div className={"header-container bm-30"}>
                <header id="back" className="imx-header">
                    <h1>Investor Movement Index<sup>®</sup> (IMX<sup>SM</sup>): {this.props.monthDate.toString()}</h1>

                    {(this.props.isPrint)?null:
                        <div>
                            <div className={"toolbar-container float-r"}>
                                <div className={'header-share-link'}>
                                    <div className={"header-link  float-l"}><div data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["print"]}' onClick={ (evt) => {this.props.printHandler();  }}><div className={"img-print icon-16"}></div>Print</div></div>
                                </div>

                                <div className={'header-share-link'}>
                                    <div className={"header-link float-l"}><div data-sitevendortrack='{"actionName":"unaffiliatedLinkClick","details":["share"]}' onClick={ (evt) => {this.shareToggle(); }}><div className={"img-share icon-16"}></div>Share</div></div>
                                </div>

                            </div>

                            <SimpleShare isShareOpen={this.state.isShareOpen} shareToggle={this.shareToggle} friendsToggle={this.friendsToggle}/>

                            <ShareWithFriends isFriendsOpen={this.state.isFriendsOpen} friendsToggle={this.friendsToggle}/>
                        </div>
                    }

                </header>
            </div>
        );

    }

}

export default Header;
