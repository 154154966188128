import React, { Component } from 'react';
import './HeaderSchw.css';

class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShareOpen:false,
            isFriendsOpen:false
        }

    }

    shareToggle = () => {

        this.setState({
            isShareOpen:!this.state.isShareOpen
        })

    }

    friendsToggle = () => {

        this.setState({
            isFriendsOpen:!this.state.isFriendsOpen
        })

    }



    render(){

        return (
            <div className={"header-container bm-30"}>
                <header id="back" className="imx-header-schw">
                    <div className={"txt-Helvetica36-schw"}>Schwab Trading Activity Index<sup>TM</sup> (STAX)</div>

                </header>
            </div>
        );

    }

}

export default Header;
