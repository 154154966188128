import React from 'react';
// import PropTypes from 'prop-types';
import ReactJWPlayer from 'react-jw-player';
import './Video.css';

// const displayName = 'ReactJWPlayerContainer';

const propTypes = {
    //playlist: PropTypes.string.isRequired,
    //playerScript: PropTypes.string.isRequired
};

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError:false
        };
        this.baseState = this.state
        this.onAdPlay = this.onAdPlay.bind(this);
        this.onReady = this.onReady.bind(this);
        this.onVideoLoad = this.onVideoLoad.bind(this);
        this.playerId = String(Math.floor(Math.random() * 200000000));
    }
    onReady(event) {
        // interact with JW Player API here
        // const player = window.jwplayer(this.playerId);
    }
    onAdPlay(event) {
        // track the ad play here
    }
    onVideoLoad(event) {
        this.setState({
            videoTitle: event.item.description // this only works with json feeds!
        });
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {

        if (this.state.hasError) {
            return null;
        }

        //TODO make image dynamic to enviroment
        return (

            <div className='react-jw-player-container bm-30'>
                <ReactJWPlayer
                    // playlist={this.props.playlist}
                    licenseKey='OjjuzXdLd53EII8cG8OjwfVf0XAPsucNwYvIwroFdcg='
                    //onAdPlay={this.onAdPlay}
                    file={this.props.video.videoFile}
                    //image={this.isImage('https://imx.tdameritrade.com/' + this.props.video.videoPoster)}
                    image={this.props.video.videoPoster}
                    onReady={this.onReady}
                    onVideoLoad={this.onVideoLoad}
                    playerId={this.playerId} // bring in the randomly generated playerId
                    playerScript='https://www.tdameritrade.com/retail-en_us/resources/javascript/responsive/js/jwplayer/jwplayer.js'
                />
                {/*<h1>{ this.props.video.videoTitle }</h1>*/}
            </div>
        );
    }
}

Video.propTypes = propTypes;
//ReactJWPlayerContainer.defaultProps = defaultProps;
//Video.displayName = displayName;
export default Video;







