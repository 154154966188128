import React, { Component } from 'react';
import './Symbols.css';



class Symbols extends Component {

    constructor(props){
        super(props);

        this.state = {
            isChecked:false,
            allChecked:[]
        };

    }

    toggleCheck = (val) => {
        console.log(val);
        let aCheckSingle=this.state.allChecked;
        aCheckSingle[val]=!aCheckSingle[val];
        this.setState({
            allChecked: aCheckSingle
        });
    }

    toggleAll = (evt) => {
        let aCheckAll=this.state.allChecked.map((bool) => evt.target.checked);
        this.setState({
            isChecked: evt.target.checked,
            allChecked: aCheckAll
        });
    }

    addSymbol = () => {
        let aChecked=[];
        [...document.querySelectorAll('.symbol-input')].map(input => {
            if(input.checked){aChecked.push(input.name)}
        })
        if (window.TDAG && window.TDAG.watchList) {
            window.TDAG.watchList.add(aChecked);
        }
    }

    componentWillReceiveProps(nextProps){
        let aCheckAll=this.state.allChecked.map((bool) => false);
        document.getElementsByName("Select all")[0].checked=false;
        this.setState({
            isChecked: false,
            allChecked: aCheckAll
        });
    }

    symbolChanged = () => {};


    render() {

        let mySymbols = this.props.symbols.sort().map((symbol,i) => {
            this.state.allChecked.push(false)
            return(
                <div  className={'symbol-flex-item'} key={"symbol-checkbox"+i}>
                    <input className={"symbol-input"}
                           name={symbol} type="checkbox" value={symbol}
                           onClick={(evt) => {
                               this.toggleCheck(i);
                           }}
                           onChange={this.symbolChanged}
                           checked={this.state.allChecked[i]}
                    />
                    <span>{symbol}</span>
                </div>
            );
        });

        return (

            <div id={"symbols-container"} className={"symbols-container"}>

                <div className={"txt-Helvetica14 border-bottom-01 bm-10"}>Symbols Referenced</div>
                <form action="">
                    <div className={"bm-10"}>
                        <input name="Select all" type="checkbox" value="SelectAll" onChange={this.toggleAll.bind(this)}/>
                        Select all
                    </div>
                    <div className={'checkboxes-container'}>

                        <div className={'symbol-flex-container bm-20'}>
                            {mySymbols}
                        </div>

                    </div>

                    <div className={"btn center cb"} onClick={this.addSymbol} data-sitevendortrack='{"actionName":"addToWatchlist","details":["add to watchlist"]}'>Add to watch list</div>
                </form>


            </div>
        );
    }
}

export default Symbols;
//todo update symbol refresh to react

