import React from 'react';
import './ShareWithFriends.css';
//Todo Check on GIGYA social link address and add email modal and ask if still want popups
//TODO add form send
//TODO Social icon clicks
export default function ShareWithFriends(props) {

    if(!props.isFriendsOpen){
        return null;
    }

   // onclick="gigya.services.socialize.plugins.reactions.instances['shareButtons'].buttonInstances['shareButtons-reaction0'].onClick()"

    return (
        <div className={'share-main-container'}>

            <div className="share-header-container">

                <div className="simple-share-header-text float-l"><strong>Share with your friends</strong></div>

                <div className={'share-close'} onClick={props.friendsToggle}>
                    <div className="share-close-btn float-r"><div className={'img-close-dialog-white icon-16'}></div></div>
                </div>

            </div>


            <div className={'share-social-bar-container'}>

                <div className={'share-social-content-container'}>
                    <div className={'float-l'}>Import contacts and email to friends:</div>
                    <div className={'icon-google friends-icon-30 float-l'}></div>
                    <div className={'icon-linkedIn friends-icon-30 float-l'}></div>
                    <div className={'icon-yahoo friends-icon-30 float-l'}></div>
                </div>

                <div className={'share-form cb'}>

                    <form action="">

                        <div className={'form-item'}>
                            <div className={'form-label'}><strong>To:</strong></div>
                            <input type="text" placeholder='Separate multiple addresses with commas'/>
                        </div>

                        <div className={'form-item'}>
                            <div className={'form-label'}><strong>From:</strong></div>
                            <input type="text" placeholder='Your email address'/>
                        </div>

                        <div className={'form-item'}>
                            <div className={'form-label'}><strong>Message:</strong></div>
                            <input className={'input-message'} type="text"/>
                        </div>

                        <button className={'share-btn'}>Send</button>

                    </form>

                </div>


            </div>


        </div>
    );
}


