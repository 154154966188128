import React, { Component } from 'react';
import './SubscriptionConfirmed.css';

class SubscriptionConfirmed extends Component {

    render(){
        return (
            <div className={'confirmed-main-container'}>
                <div className={'confirmed-content-container'}>
                    <div className={"txt-Helvetica20 border-bottom bm-30"}>Subscription Confirmed</div>
                    <p className={'bm-20'}>You will receive email notifications when IMX monthly summaries are released.</p>
                    <div className={'confirmed-input-container'}>
                        <div className={'btn'} onClick={() => {this.props.toggleModal()}}>Close</div>
                    </div>
                </div>
            </div>
        );
    }


}

export default SubscriptionConfirmed;