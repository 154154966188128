import React from 'react';
import Interstitial from './Interstitial'
import './Media.css';
export default function Media(props) {

    let listMedia = [];

    function handleIntersticial(evt){
        evt.preventDefault();

        props.toggleModal(<Interstitial toggleModal={props.toggleModal}/>)

    }



    if(props.articles!==null && props.articles!==undefined){

        listMedia = props.articles.map((article, index) => {
            return (

                <li className={"article-li"} key={"article-"+index}>
                    {(props.public)?
                        <div className={"article-container bm-3"}><a className={"txt-Helvetica14 article-link"} data-sitevendortrack='{"actionName":"articleClick","details":["media:full story"]}' href={article.url} target="_blank"><div className={"txt-article float-l"}>{article.title}&nbsp;</div><div className={"img-new-window icon-16"}></div></a></div>
                    :
                        <div className={"article-container bm-3"}>
                            <a className={"txt-Helvetica14 article-link"}
                               data-sitevendortrack='{"actionName":"articleClick","details":["media:full story"]}'
                                 data-third-party-link='{"category":"affiliated", "hasNoSession":false}'
                                 href={article.url}
                                 target="_blank">
                            <div className={"txt-article float-l"}>{article.title}&nbsp;</div>
                            <div className={"img-new-window icon-16"}></div>
                            </a>
                        </div>

                    }

                    <div className={"txt-Helvetica11 txt-source cb"}>{article.publishedDate} ({article.source})</div>
                </li>

            );
        });
        return (
            <div className={"media-container"}>
                <div className={"txt-Helvetica16 border-bottom"}>In the Media</div>
                <div className={"media-content-container"}>
                    <p className={"txt-Helvetica12 txt-latest bm-20"}>Latest headlines</p>
                    <ul className={"articles-container"}>{listMedia}</ul>
                    <a className="green-link float-r" href="#back">Back to top</a>
                </div>


            </div>
        );


    }else{

        return (
            <div>
                <a className="green-link float-r" href="#back">Back to top</a>
            </div>
        );
    }


}











