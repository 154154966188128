import React, { Component } from 'react';
import ChartTablesSchw from './ChartTablesSchw';
import { formatMonthDate } from './utilities';
import Tabs from './Tabs';
import TooltipSchw from './TooltipSchw';
import { Line } from 'react-chartjs-2';
import Draggable from 'react-draggable';

import './ChartsSchw.css';

class ChartsSchw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initChart: true,
            isMaxChart: false,
            xLabelsIndex: 2,
            dataPointValue: 0.00,
            dataPointIndex: null,
            highlightPoint: [],
            highlightWidth: 40,
            highlightX: 2480,
            highlightToBeX: 0,
            mouseInside: true,
            chartWidth: 720,
            chartDragWidth: 0,
            chartHeight: 450,
            showTooltip: false,
            chartRedraw: true,
            aTimeFrame: ['time-inactive', 'time-inactive', 'time-active', 'time-inactive', 'time-inactive'],
            x: 0,
            y: 0
        };
    }


    handlerTooltip = () => {
        this.setState({
            showTooltip: !this.state.showTooltip,
            mouseInside: !this.state.mouseInside,
        });
    }

    handlerTimeFrame = (index) => {
        const aActive = ['time-inactive', 'time-inactive', 'time-inactive', 'time-inactive', 'time-inactive'];
        let myLabelsIndex = 2;
        let myTimeWidth = 579;
        let myhighlightWidth = 16;
        aActive[index] = 'time-active';
        this.setState({
            isMaxChart: index == 4 || index == 3
        });

        switch (index) {
            case 0:
                myLabelsIndex = 0;
                myTimeWidth = this.props.dataPoints.length * 100;
                myhighlightWidth = 100;
                break;
            case 1:
                myLabelsIndex = 0;
                myTimeWidth = this.props.dataPoints.length * 48;
                myhighlightWidth = 48;
                break;
            case 2:
                myLabelsIndex = 1;
                myTimeWidth = this.props.dataPoints.length * 24;
                myhighlightWidth = 24;
                break;
            case 3:
                myLabelsIndex = 1;
                myTimeWidth = this.props.dataPoints.length * 16;
                myhighlightWidth = 16;
                break;
            case 4:
                myLabelsIndex = 2;
                myTimeWidth = 579
                myhighlightWidth = 8;
                break;
            default:
                myLabelsIndex = 1;
                myTimeWidth = this.props.dataPoints.length * 16
                myhighlightWidth = 16;
        }


        this.setState({
            aTimeFrame: aActive,
            chartDragWidth: myTimeWidth,
            chartRedraw: false,
            xLabelsIndex: myLabelsIndex,
            highlightWidth: myhighlightWidth
        })

        setTimeout(function () {
            this.setState({
                chartRedraw: true
            });

            if (this.refs.chartDrag && this.refs.chartDrag.chartInstance.getDatasetMeta(0).data[0]) {
                let aHighlightPoint = this.refs.chartDrag.chartInstance.getDatasetMeta(0).data.map(

                    (point) => {
                        return (point._model.x);
                    });

                this.setState({
                    highlightPoint: aHighlightPoint.reverse(),
                });

                this.moveHighlight(this.props.currentValue)
            }


        }.bind(this), 1);


    }

    moveHighlight(index) {
        this.setState({
            highlightX: this.state.highlightPoint[index]
        });
    }

    componentDidMount() {
        this.handlerTimeFrame(this.state.xLabelsIndex);

    }

    componentWillReceiveProps() {
        if (this.state.highlightPoint.length >= 1) { this.moveHighlight(this.props.currentValue) }
        if (this.props.dataPoints.length >= 1 && this.state.initChart) {
            this.handlerTimeFrame(3);
            this.setState({
                initChart: false
            });

        }
    }

    render() {
        const aGridColors = [null];
        const a6mTo1yr = [""];
        const a2yrTo3yr = [""];
        const aAll = [""];
        const aPick = [a6mTo1yr, a2yrTo3yr, aAll];
        const aPointRadius = [null];
        const aPointHitRadius = [null];

        this.props.dataPoints.map((dataPoint, i) => {
            const myMonthCheck = formatMonthDate(dataPoint.date, 'ms');
            (dataPoint.hasSummary) ? aPointRadius.push(5) : aPointRadius.push(5);
            (dataPoint.hasSummary) ? aPointHitRadius.push(2) : aPointHitRadius.push(2);
            // Grid Colors
            if (myMonthCheck === 'JAN' || myMonthCheck === 'APR' || myMonthCheck === 'JUL' || myMonthCheck === 'OCT') {
                aGridColors.push('rgba(0, 0, 0, 0.05)');
            } else {
                aGridColors.push('rgba(0, 0, 0, 0.00)');
            }

            // 6 month and 1 yr
            if (myMonthCheck === 'JAN' || myMonthCheck === 'DEC' || i === 0) {
                a6mTo1yr.push([[formatMonthDate(dataPoint.date, 'ms')], formatMonthDate(dataPoint.date, 'yyyy')]);
            } else {
                a6mTo1yr.push(formatMonthDate(dataPoint.date, 'ms'));
            }

            // 2 and 3 year
            if (myMonthCheck === 'DEC') {
                a2yrTo3yr.push([[formatMonthDate(dataPoint.date, 'ms')], formatMonthDate(dataPoint.date, 'yyyy')]);
            } else if (myMonthCheck === 'MAR' || myMonthCheck === 'JUN' || myMonthCheck === 'SEP') {
                a2yrTo3yr.push([[formatMonthDate(dataPoint.date, 'ms')], ''])
            } else {
                a2yrTo3yr.push('');

            }

            // All
            if (myMonthCheck === 'MAR' || myMonthCheck === 'SEP') {
                aAll.push([[formatMonthDate(dataPoint.date, 'ms')], '']);
            } else {
                aAll.push('');
            }
            return ([[formatMonthDate(dataPoint.date, 'ms')], formatMonthDate(dataPoint.date, 'yyyy')]);

        });

        dataDrag.labels = aPick[this.state.xLabelsIndex].reverse();

        const aImxScore = this.props.dataPoints.map((dataPoint) => {
            return (dataPoint.imxScore);
        });
        dataDrag.datasets[0].data = aImxScore.reverse();

        const aSpxScore = this.props.dataPoints.map((dataPoint) => {
            return (dataPoint.spxScore);
        });
        dataDrag.datasets[1].data = aSpxScore.reverse();
        dataDrag.datasets[0].pointRadius = aPointRadius.reverse();
        dataDrag.datasets[0].pointHitRadius = aPointHitRadius.reverse();

        optionsDrag.width = this.state.chartDragWidth;

        //highlight
        optionsDrag.onClick = function () {
            if (this.state.mouseInside) {

                if (this.state.dataPointIndex != null) this.props.handleChange(this.state.dataPointIndex, "chart");
            }


        }.bind(this);


        optionsDrag.width = this.state.chartDragWidth;

        optionsDrag.scales.xAxes[0].gridLines.color = aGridColors;



        optionsDrag.tooltips = {
            enabled: false,
            custom: this.handlerTooltip,
            callbacks: {
                label: function (tooltipItem) {
                    const myIndex = this.props.dataPoints.length - 1 - tooltipItem.index
                    this.setState({
                        highlightToBeX: tooltipItem.x,
                        x: tooltipItem.x,
                        y: tooltipItem.y,
                        dataPointValue: tooltipItem.yLabel.toFixed(2),
                        dataPointIndex: (this.props.dataPoints[myIndex].hasSummary) ? myIndex : null,
                        showTooltip: false,
                        mouseInside: false
                    });
                }.bind(this)
            }
        }

        return (
            <div className={'bm-50'}>

                <div className={'tab-container-schw'}>
                    <div className={'container-border-schw'} />
                    <Tabs label={'chart'}>
                        <div label='Chart View' role={"tab"} aria-selected={"true"}>
                            {/* <div className={'point-info-container'}>{this.props.chartInfo}</div> */}
                            <div className={'chart-container-schw'}>
                                <div className={'point-info-container-schw'}>{this.props.chartInfo}</div>
                                <div className={'labelSPX-schw'}><strong>SPX</strong></div>
                                <div className={'labelIMX-schw'}><strong>STAX</strong></div>
                                <div className={'options-container-schw'}>
                                    <div className={'options-list-container-schw'}>
                                        <ul className={'options-list'}>
                                            <li><button className={'chart-btn-schw ' + this.state.aTimeFrame[0]} onClick={() => { this.handlerTimeFrame(0) }} data-sitevendortrack='{"actionName":"chartClick","details":["6m"]}' name={"6M"} aria-label='6 Months Chart'>6M</button></li>
                                            <li><button className={'chart-btn-schw ' + this.state.aTimeFrame[1]} onClick={() => { this.handlerTimeFrame(1) }} data-sitevendortrack='{"actionName":"chartClick","details":["1Y"]}' name={"1Y"} aria-label='1 Year Chart'>1Y</button></li>
                                            <li><button className={'chart-btn-schw ' + this.state.aTimeFrame[2]} onClick={() => { this.handlerTimeFrame(2) }} data-sitevendortrack='{"actionName":"chartClick","details":["2Y"]}' name={"2Y"} aria-label='2 Years Chart'>2Y</button></li>
                                            <li><button className={'chart-btn-schw ' + this.state.aTimeFrame[3]} onClick={() => { this.handlerTimeFrame(3) }} data-sitevendortrack='{"actionName":"chartClick","details":["3Y"]}' name={"3Y"} aria-label='3 Years Chart'>3Y</button></li>
                                            <li><button className={'chart-btn-schw ' + this.state.aTimeFrame[4]} onClick={() => { this.handlerTimeFrame(4) }} data-sitevendortrack='{"actionName":"chartClick","details":["all"]}' name={"All"} aria-label='All Years'>All</button></li>
                                        </ul>
                                    </div>

                                    <div className={'legend-container-schw'}>
                                        <div className={'legend-item-schw float-r'}><div className={'img-graph-line icon-16'}></div><strong>SPX</strong></div>
                                        <div className={'legend-item-schw float-r'}>
                                            <div className={'icon-16'}><img className={'icon-chart-line-schw'} src={require('.././img/IMX-Chart-Line.svg')} alt="Line" /></div><strong>STAX</strong>
                                        </div>

                                    </div>

                                </div>

                                <div className={'chart-graph-container bm-15'}>
                                    <div className={'charts-container'}>
                                        <div>

                                            <div className={'chart-base-container'}>
                                                <Line
                                                    data={data}
                                                    options={options}
                                                    width={this.state.chartWidth}
                                                    height={this.state.chartHeight}
                                                />

                                            </div>

                                            <div className={'chart-view-control-schw'}>
                                                <div id={"chartBorder"} className={'chart-border'} />
                                                <div id={'myChart'} className={`${this.state.isMaxChart ? "chart-max-draggable-container-schw" : "chart-draggable-container-schw"}`}>
                                                    {(this.state.chartRedraw) ?
                                                        <Draggable defaultPosition={{ x: this.state.highlightWidth - 13, y: 0 }} axis="x" bounds={{ top: 0, left: this.state.highlightWidth - 13, right: (this.state.chartDragWidth - 585), bottom: 0 }}>
                                                            <div>

                                                                {(this.props.isPrint) ? null :
                                                                    <div id='chartHighlight' className={"chart-highlight-schw"} style={{
                                                                        left: this.state.highlightX - ((this.state.highlightWidth * 0.5)),
                                                                        width: this.state.highlightWidth
                                                                    }}
                                                                    />
                                                                }

                                                                <TooltipSchw showTooltip={this.state.showTooltip}
                                                                    dataPointValue={this.state.dataPointValue}
                                                                    x={this.state.x}
                                                                    y={this.state.y}
                                                                />


                                                                <Line
                                                                    id={'drag-chart'}
                                                                    ref='chartDrag'
                                                                    width={this.state.chartDragWidth}
                                                                    height={this.state.chartHeight}
                                                                    data={dataDrag}
                                                                    options={optionsDrag}
                                                                //getDatasetAtEvent={(dataset) => {console.log(dataset)} }
                                                                />

                                                            </div>
                                                        </Draggable>
                                                        : null}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className={'txt-disclaimer txt-Helvetica12-schw bm-10'}>To see a different month, click a data point in the chart.</div>
                                <div className={'txt-disclaimer txt-Helvetica12-schw bm-10'}>The STAX begins incorporating Schwab client data as of January 2019.</div>

                            </div>
                        </div>

                        <div label='Table View' role={"tab"} aria-selected={"false"}>
                            {/* <div className={'point-info-container'}>{this.props.chartInfo}</div> */}

                            <div id={'main-table-container-schw'} className={'main-table-container-schw'}>
                                <div className={'point-info-container-schw'}>{this.props.chartInfo}</div>

                                <ChartTablesSchw
                                    dataPoints={this.props.dataPoints}
                                    handleChange={this.props.handleChange}
                                    monthDate={this.props.monthDate}
                                />
                            </div>

                        </div>
                    </Tabs>
                </div>
            </div>

        );
    }
}


export default ChartsSchw;


const dataDrag = {
    labels: [""],
    datasets: [

        {
            label: "STAX",
            data: [],
            yAxisID: "y-axis-1",
            fill: false,
            borderColor: "rgb(3, 125, 174)",
            borderWidth: 2,
            pointRadius: 5,
            pointBorderWidth: 2,
            pointHitRadius: 2,
            pointBackgroundColor: "#ffffff",
            pointHoverRadius: 8,
            pointHoverBorderWidth: 3,
            pointHoverBackgroundColor: '#ffffff',
            lineTension: 0.3,
        },

        {
            label: "SPX",
            data: [2100],
            yAxisID: "y-axis-2",
            pointsStrokeColor: true,
            borderColor: "rgba(187, 187, 187,1)",
            backgroundColor: "rgba(187, 187, 187,.15)",
            borderWidth: 2,
            fillColor: "rgba(241, 241, 241,.1)",
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 0,
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: 'rgb(241, 241, 241)',
            pointStyle: 'rect',
            lineTension: 0

        },



    ]
};

const data = {
    labels: [[[""], ""], [[""], ""]],
    datasets: [
        {
            label: "SPX",
            data: [2100, 2780],
            hidden: true,
            yAxisID: "y-axis-1",
            pointsStrokeColor: false,
            borderColor: "grey",
            pointRadius: 0,
            pointHitRadius: 0
        },
        {
            label: "STAX",
            data: [5, 7],
            hidden: true,
            yAxisID: "y-axis-2",
            fill: false,
            borderColor: "green",
        }
    ]
};



//TODO make chart ranges dynamic
///////////////////////////////////////////////////////chart datapoints
const optionsDrag = {
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    height: '450px',
    width: '5000px',
    hoverMode: 'label',
    stacked: false,
    spanGaps: false,



    events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend"],

    title: {
        display: false,
        text: 'STAX'
    },

    animation: {
        duration: 0
    },

    legend: {
        display: false,
        position: 'top',
    },

    scales: {

        xAxes:
            [{

                display: true,
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                gridLines: {
                    gridlines: true,
                    offsetGridLines: true,
                    drawTicks: true,
                    tickMarkLength: 5,
                    drawOnChartArea: true,
                    color: [],
                    lineWidth: 2,
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                    //min:0,
                    //max: 10000,
                    //maxTicksLimit: 10000,
                    maxRotation: 0,
                    autoSkip: false,
                    fontSize: 11,
                    fontFamily: "Tahoma",

                }
            }],

        yAxes: [


            {
                type: "linear",
                display: false,
                pointStyle: "rect",
                position: "right",
                id: "y-axis-1",

                scaleLabel: {
                    display: false,
                    labelString: "STAX",
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 5,
                    min: 30,
                    max: 100,
                    fontSize: 11,
                    fontFamily: "Tahoma"
                },

                gridLines: {
                    display: false,
                    lineWidth: 1

                    // drawOnChartArea: false,
                },

            },


            {
                type: "linear",
                display: false,
                position: "left",
                id: "y-axis-2",


                scaleLabel: {
                    display: false,
                    labelString: "SPX"
                },

                ticks: {
                    beginAtZero: true,
                    stepSize: 500,
                    min: 2000,
                    max: 6500,
                    fontSize: 11,
                    fontFamily: "Tahoma",

                },

                gridLines: {
                    display: false,
                    lineWidth: 0
                    // drawOnChartArea: false,
                }
            },


        ]
    }
};

//////////////////////////////////////////////////////Chart Axes
const options = {
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    hoverMode: 'label',
    stacked: false,
    title: {
        display: false,
        text: 'STAX'
    },

    animation: {
        duration: 0
    },

    legend: {
        display: false,
        position: 'top',
    },

    scales: {

        xAxes: [{

            display: true,
            scaleLabel: {
                display: false,
                labelString: 'Month'
            },
            gridLines: {
                offsetGridLines: false,
                tickMarkLength: 5,


            },
            ticks: {
                beginAtZero: true,
                stepSize: 4,
                min: 0,
                max: 36,
                maxRotation: 0,
                autoSkip: false,
                fontSize: 11,
                fontFamily: "Tahoma",
            },
        }],

        yAxes: [{
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",

            scaleLabel: {
                display: true,
                // labelString: "SPX"
            },
            ticks: {
                beginAtZero: true,
                stepSize: 500,
                min: 2000,
                max: 6500,
                fontSize: 11,
                fontFamily: "Tahoma",
                userCallback: function (value) {

                    // Convert the number to a string and splite the string every 3 charaters from the end
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return value;
                }
            },


            gridLines: {
                display: false,
                lineWidth: 0

            }
        },

        {
            type: "linear",
            display: true,
            pointStyle: "rect",
            position: "right",
            id: "y-axis-2",

            scaleLabel: {
                display: true,
                // labelString: "IMX",
            },

            ticks: {
                beginAtZero: true,
                stepSize: 5,
                min: 30,
                max: 100,
                fontSize: 11,
                fontFamily: "Tahoma"

            },

            gridLines: {
                display: false,
                lineWidth: 0
                // drawOnChartArea: false,
            }
        }]
    }
};





