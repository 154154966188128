import React from 'react';
import ChartsSchw from '../components/ChartsSchw';
import { formatMonthDate } from '../components/utilities';
import HeaderSchw from '../components/HeaderSchw';
import SummaryIMXSchw from '../components/SummaryIMXSchw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { fetch as fetchPolyfill } from 'whatwg-fetch';



export default class extends React.Component {
  constructor(props) {
    super(props);

    this.updateMonthDate = this.updateMonthDate.bind(this);

    this.state = {
      drop: 0,
      currentValue: 0,
      articles: [],
      summary: [],
      readingData: {},
      monthDate: '',
      chartInfo: '',
      video: {},
      dataPoints: [],
      isLoading: false,
      hasError: false
    };
  }

  updateMonthDate = (value, eventClick = "chart table") => {
    this.setState({ currentValue: value });
    this.setState({ isLoading: true });

    fetchPolyfill(this.props.api + '/imxData/' + formatMonthDate(this.state.dataPoints[value].date, 'mmddyyyy')+'?isStax=true', {
      method: 'get',
      headers: this.props.apiKey ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'apikey': this.props.apiKey
      } : {}
    })
      .then(res => {
        this.setState({ isLoading: false });
        if (res.status !== 200) {
          this.setState({ hasError: true });
        }
        return res.json()
      })
      .then(json => {
        // console.log(json);
        this.setState({
          drop: this.state.dataPoints[value].summaryIndex,
          articles: json.imxArticles,
          summary: json.summary,
          readingData: json,
          video: { videoCaption: json.videoCaption, videoFile: json.videoFile, videoPoster: json.videoPoster },
          chartInfo: formatChartInfo(json),
          monthDate: formatMonthDate(this.state.dataPoints[value].date)
        });

        if (window.TDAG && window.TDAG.analytics) {

          window.TDAG.analytics.track({

            actionName: "eventClick",

            details: ["summary " + eventClick + ": " + this.state.monthDate.toString()]

          });

        }

        //Highlight on tables///////////////////////////////////
        const rows = document.getElementsByClassName("row-info");
        for (var i = 0; i < rows.length; i++) {
          rows[i].classList.remove("item-active-schw");
          if (rows[i].dataset.date === this.state.monthDate) {
            rows[i].classList.add("item-active-schw");
          }
        }

      });




  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dataPoints !== this.state.dataPoints) {
      this.updateMonthDate(0);
    }
  }



  componentDidMount() {
    let summaryIndex = 0;
    this.setState({ isLoading: true });
    
    fetchPolyfill(this.props.api + '/chartData?isStax=true', {
        method: 'get',
        headers: this.props.apiKey ? {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'apikey': this.props.apiKey
        } : {}
    })
      .then(res => {
        if (res.status == 200) {
          this.setState({
            isLoading: false,
            hasError: false
          });
        }
        return res.json()
      })
      .then(json => {
        this.setState({
          monthDate: formatMonthDate(json[json.length - 1].date),
          dataPoints: json.reverse().map((obj, i) => {
            //(obj.hasSummary)? (obj.summaryIndex=summaryIndex, summaryIndex++):null;
            if (obj.hasSummary) { obj.summaryIndex = summaryIndex; summaryIndex++; };
            obj.year = obj.date.slice(-4);
            obj.val = i;
            return (obj)
          })
        });

        if (this.state.dataPoints) {
          this.updateMonthDate(0);
        }

      }).catch(error => {
        this.setState({ isLoading: false });
        this.setState({ hasError: true });
      });
  }

  render() {
    if (!this.state.isLoading && !this.state.hasError) {
      return showIMX(this.props, this.state, this.updateMonthDate);
    } else if (this.state.isLoading) {
      return <div class="page-loader">
        <img src={require('.././img/spinnerSchw.svg')} alt="Loading" />
      </div>
    } else {
      return showUnavailableMessage();
    }
  }
}
const adobeClick = () => {
  try {
    const tagMessage = {
      "eventType": "trackEvent",
      "tagString": "adobe-lc_subscribe"
    };
    window.parent.postMessage(tagMessage, '*');
    console.log(postMessage);
  } catch (err) {
    console.log('iFrame Analytics Handler Error - Inner, GUT, triggering call: ' + err.message);
  }
}
//todo make this a component
const formatChartInfo = (dayData) => {
  const imxUp = (dayData.imxScoreChange >= 0) ? 'trend-up-schw' : 'trend-down';
  const spxUp = (dayData.spxScoreChange >= 0) ? 'trend-up-schw' : 'trend-down';
  return (
    <div id={'tabs-header'} className={'txt-Helvetica13-schw float-r'}>


      <div id={'title-spx'} className={'txt-Helvetica13-schw float-l'} tabIndex={0}>
        <div className={'chart-info-box '}>SPX | {dayData.spxScore}</div>
        <div className={'chart-info-box ' + spxUp}>
          <span>{spxUp === "trend-down" ?
            <FontAwesomeIcon icon={faCircleArrowDown} color={"#037dae"} /> :
            <FontAwesomeIcon icon={faCircleArrowUp} color={"#037dae"} />
          }</span>
          {dayData.spxScoreChange.toFixed(2)} ({(spxUp === "trend-down") ? null : "+"}{dayData.spxScoreChangePercent.toFixed(2)}%)
        </div>
      </div>

      <div id={'title-imx'} className={'txt-Helvetica13-schw float-l'} tabIndex={0}>
        <div className={'chart-info-box '}>STAX | {dayData.imxScore}</div>
        <div className={'chart-info-box ' + imxUp}>
          <span>{imxUp === "trend-down" ?
            <FontAwesomeIcon icon={faCircleArrowDown} color={"#037dae"} /> :
            <FontAwesomeIcon icon={faCircleArrowUp} color={"#037dae"} />
          }</span>
          {dayData.imxScoreChange.toFixed(2)} ({(imxUp === "trend-down") ? null : "+"}{dayData.imxScoreChangePercent.toFixed(2)}%)</div>
      </div>


    </div>
  );

};

function showIMX(props, state, updateMonthDate) {

  return (

    <div>

      <div>

        <div id={'myChart'}></div>

        <ChartsSchw
          chartInfo={state.chartInfo}
          currentValue={state.currentValue}
          dataPoints={state.dataPoints}
          handleChange={updateMonthDate}
          isPrint={props.isPrint}
          monthDate={state.monthDate}
        />

      </div>
      <SummaryIMXSchw
        drop={state.drop}
        monthDate={state.monthDate}
        summary={state.summary}
        dataPoints={state.dataPoints}
        readingData={state.readingData}
        video={state.video}
        handleChange={updateMonthDate}
        isPrint={props.isPrint}
        public={props.public}
      />
    </div>

  );
}

function showUnavailableMessage() {
  return (
    <div>

      <h2 style={{ paddingTop: "75px" }}>STAX page is temporarily unavailable, please try again later.</h2>
    </div>

  );
}