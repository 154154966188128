import React, {Component} from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./SimpleCaptcha.css"

import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    LoadCanvasTemplateNoReload,
    validateCaptcha
} from "react-simple-captcha";

class SimpleCaptcha extends Component {
    componentDidMount() {
        loadCaptchaEnginge(8);
        this.state = {
            captchaMessage: "test"
        }
        //this.handleSubmit = this.handleSubmit.bind(this);
    }


    render() {

        return (
            <div>
                <div className="captcha">
                    <div className="form-group">

                        <div className="row pb-2">
                            <div className="col">
                                <LoadCanvasTemplate/>
                            </div>
                            {!this.props.validCaptcha && <div className="col"><span className={'captchaMsg'}>Please Enter Valid Captcha</span></div>
                            }
                        </div>

                        <div className="row">
                            <div className="col">
                                <div>
                                    <input
                                        placeholder="Enter Captcha"
                                        id="user_captcha_input"
                                        onChange={this.props.onChange}
                                        name="user_captcha_input"
                                        type="text"
                                    ></input>
                                </div>
                            </div>

                            <div className="col">

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default SimpleCaptcha;