import React, { Component } from 'react';
import './AlreadySubscribed.css';

class AlreadySubscribed extends Component {

    render(){
        return (
            <div className={'subscribe-main-container'}>
                <div className={'subscribe-content-container'}>
                    <div className={"txt-Helvetica20 border-bottom bm-30"}>Already Subscribed</div>
                    <p className={'bm-20'}>The email address you provided is already subscribed for monthly IMX summaries. Add <a className={'already-subscribed-email'} href="mailto:tdameritradeIMX@welcome.tdameritrade.com">tdameritradeIMX@welcome.tdameritrade.com</a> to your contacts to insure delivery.</p>
                    <div className={'subscribe-input-container'}>
                        <div className={'btn'} onClick={() => {this.props.toggleModal()}}>Close</div>
                    </div>
                </div>
            </div>
        );
    }


}

export default AlreadySubscribed;