import React from 'react';
import Dropdown from './Dropdown';
import ReadingIMX from './ReadingIMX';
import Symbols from './Symbols';
import { formatMonthDate} from './utilities';
import Video from '../components/./Video';
import './SummaryIMX.css';
import Parser from 'html-react-parser';

class SummaryIMX extends React.Component {


    render() {
        let cName = sessionStorage.getItem("cName") || "investsdev07__VENDOR";
        const parseOptions = {
            replace: (domNode) => {
                // do not replace if element has no attributes
                if (!domNode.attribs) return;

                if (domNode.attribs.class === 'symlink') {

                    if(this.props.public){
                        return (
                            <a
                                className={domNode.attribs.class}
                                target='_blank'
                                href={domNode.attribs.href}
                                data-sitevendortrack={'"actionName":"eventClick","details":["summary:symbol click"]'}
                            >
                                {domNode.attribs.symbol}
                            </a>
                        );

                    }else{

                        return (
                            <a
                                className={domNode.attribs.class}
                                target='_blank'
                                href={domNode.attribs.href}
                                data-tda-symbol={'{"symbol":"' + domNode.attribs.symbol + '", "securityType":"E"}'}
                                data-sitevendortrack={'"actionName":"eventClick","details":["summary:symbol click"]'}
                            >
                                {domNode.attribs.symbol}
                            </a>
                        );
                    }


                }
            }
        };

        let mySummary=this.props.summary.toString().replace(/#,\s*#/g, '#),(#');
        let symbolIndex=0;
        const aSymbols=[];
        const aSummary=this.props.dataPoints.filter( (dataPoint) => dataPoint.hasSummary );
        let minDataPoint = {};
        let maxDataPoint = {};        
        if (this.props.dataPoints && this.props.dataPoints.length > 0) {
            let minScore = Math.min.apply(null, this.props.dataPoints.map(function (item) {
                return item.imxScore;
            }));
            let maxScore = Math.max.apply(null, this.props.dataPoints.map(function (item) {
                return item.imxScore;
            }));
            let minEntries = this.props.dataPoints.filter(val => val.imxScore == minScore);
            let maxEntries = this.props.dataPoints.filter(val => val.imxScore == maxScore);
            minDataPoint = minEntries[0];
            maxDataPoint = maxEntries[0];
        }

        const dropDownProps= aSummary.map((dataPoint) =>{
            const val=formatMonthDate(dataPoint.date);
            return({ label:val, val:dataPoint.val, hasVideo:dataPoint.hasVideo});
        });

        mySummary.replace( /\(##(.*?)##\)/g , function(match) {
            aSymbols.push(match.split('(##')[1].split("##)")[0]);
        });
        //TODO data to symbol for tos popup
        //<a className="symlink" href="http://research.tdameritrade.com/grid/public/stocks/overview/overview.asp?symbol=WMT&amp;fromPage=overview&amp;display=&amp;fromSearch=true" data-tda-symbol="{&quot;symbol&quot;:&quot;WMT&quot;,&quot;securityType&quot;:&quot;E&quot;}" data-sitevendortrack="{&quot;actionName&quot;:&quot;eventClick&quot;,&quot;details&quot;:[&quot;summary:symbol click&quot;]}">WMT</a>
        //non pub
        //



        //This <a> gets replaced in parserOptions
        mySummary=mySummary
            .replace( /\(##/g, (x) => {
                const mySymbol=aSymbols[symbolIndex];
                const myHref="http://research.tdameritrade.com/grid/public/stocks/overview/overview.asp?symbol="+aSymbols[symbolIndex]+"&amp;fromPage=overview&amp;display=&amp;fromSearch=true&amp;c_name="+cName;

                symbolIndex++;
                return('<strong>(<a class="symlink" target=_blank href='+ myHref +' symbol='+ mySymbol + '>' );
            }).replace(/##\)/g, '</a>)</strong>');



        return (
            <div id={'content-split-container'} className={"content-split-container"}>
                <div className={"txt-Helvetica16 border-bottom bm-20"}>Investor Movement Index Summary:

                    <div id ={'select-month'} className={'select-container float-r'}>



                        <Dropdown options={dropDownProps} handleChange={this.props.handleChange} drop={this.props.drop} monthDate={this.props.monthDate}/>

                    </div>

                    <div><span>{this.props.monthDate.toString()}</span></div>

                </div>

                {(this.props.video.videoFile && !this.props.isPrint)?<Video video={this.props.video}></Video>:null}


                <div id={'main-content'} className={"content-left-container txt-Helvetica12 float-l bm-30"}>


                    <div id={'contect-right'} className={"content-right-container float-r"}>
                        <ReadingIMX monthDate={this.props.monthDate} readingData={this.props.readingData} minDataPoint={minDataPoint} maxDataPoint={maxDataPoint}></ReadingIMX>

                        {(this.props.public)? null:
                            <Symbols symbols={removeDuplicate(aSymbols)}></Symbols>
                        }
                    </div>
                    <div id={'summary-style'} className={'summary-style'}>
                        {Parser(mySummary, parseOptions)}
                    </div>

                </div>
            </div>
        );
    }
}

export default SummaryIMX;


function removeDuplicate(arr){
    let unique_array = arr.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
    });
    return unique_array
}