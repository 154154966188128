import React, { Component } from 'react';
import './ReadingIMX.css';
import Tippy from '@tippy.js/react';
import 'tippy.js/themes/light-border.css';


class ReadingIMX extends Component {



    render() {
        const dayData=this.props.readingData;
        if(!dayData.trendDirection){
            return null;
        }
        const trendColor=(dayData.trendDirection==="Negative")? "trend-down": "trend-up";
        const arrowDirection=(dayData.trendDirection==="Negative")? "img-arrow-down": "img-arrow-up";
        const myRange=getRange(dayData.scoreRange);
        console.log(dayData.scoreRange);

        return (

            <div id={"reading-imx-container"} className={"reading-imx-container bm-20"}>

            <Tippy
                content={<div className={"tooltip-main-container"}><div><strong>Reading IMX</strong></div><div>The most recent value of the IMX (a proprietary measure of investor portfolio sentiment).</div></div>}
                placement="bottom"
                arrow={true}
                followCursor={true}
                theme="light-border"
                distance={25}
            >
                <div className={"txt-Helvetica14 border-bottom-01 bm-20"}>Reading (IMX)</div>
            </Tippy>

            <div className={"bm-20"}><strong>{this.props.monthDate.toString()}</strong></div>

        <div className={"reading-center bm-20"}>
            <div className={"txt-Helvetica24 imx-score float-l"}>{dayData.imxScore}</div>
            <div className={"icon-20 "+arrowDirection}></div>
            <div className={"reading-center txt-Helvetica16 float-l "+trendColor}>{dayData.imxScoreChange.toFixed(2)} ({(trendColor==="trend-down")?null:"+"}{dayData.imxScoreChangePercent.toFixed(2)}%)</div>
        </div>

        <div className={"border-bottom bm-20 cb"}></div>
            <div>

            </div>
            <Tippy
                content={<div className={"tooltip-main-container"}><div><strong>Historic Range</strong></div><div>The latest month's score as compared to the historical score range.</div></div>}
                placement="bottom"
                arrow={true}
                followCursor={true}
                theme="light-border"
                distance={25} 
            >
                <div className={"bm-20"}><strong>Historic range (since Jan 2010 inception)</strong></div>
            </Tippy>
            <Tippy
                content={<div className={"tooltip-main-container"}><div><strong>Investor Movement Index</strong></div><div>Historic Range</div>
                        <br/><div><strong>JANUARY 2010 - PRESENT</strong></div><br/>
                        <div style={{display: "flex"}}><div style={{width: "50%"}}><div>Low</div><div><strong>{this.props.minDataPoint.imxScore}</strong></div><div>{this.props.minDataPoint.date}</div></div>
                        <div><div>High</div><div><strong>{this.props.maxDataPoint.imxScore}</strong></div><div>{this.props.maxDataPoint.date}</div></div></div></div>}
                placement="bottom"
                arrow={true}
                followCursor={true}
                theme="light-border"
                distance={40} 
            >
                <div className={"bm-20"}><img src={require('.././img/ProgressBar' + myRange + '.png')} alt=""/></div>
            </Tippy>
            
            <div className={"txt-Helvetica14 border-bottom-01 bm-10"}>Trends</div>
            <Tippy
                content={<div className={"tooltip-main-container"}><div><strong>Trend Direction</strong></div><div>The trend direction shows whether the month over month change in score is positive or negative, or whether there's no change (neutral).</div></div>}
                placement="bottom"
                arrow={true}
                followCursor={true}
                theme="light-border"
                distance={25}
            >
                <div className={"txt-Helvetica12 border-bottom bm-10"}>
                    <strong>Direction</strong>
                    <div className={'float-r '+ trendColor}><div className={"icon-16 "+arrowDirection}></div>{dayData.trendDirection}</div>
                </div>
            </Tippy>
            <Tippy
                content={<div className={"tooltip-main-container"}><div><strong>Trend Length</strong></div><div>The number of consecutive months the trend direction has been positive or negative.</div></div>}
                placement="bottom"
                arrow={true}
                followCursor={true}
                theme="light-border"
                distance={25}
            >
            <div className={"txt-Helvetica12 bm-10 cb"}><strong>Length</strong></div>
        </Tippy>
            
        <div className={"trend-container"}>
            <div className={"txt-Helvetica12 bm-10"}><strong>Previous trend: </strong>{dayData.previousTrendDirection} ({dayData.previousTrendLength} month{(dayData.previousTrendLength>1)?'s':null})</div>
        <div className={"txt-Helvetica12 bm-10"}><strong>Current trend: </strong>{dayData.trendDirection} ({dayData.trendLength} month{(dayData.trendLength>1)?'s':null})</div>
        </div>


        </div>
    );
    }
}

export default ReadingIMX;


const getRange = (range) => {
    console.log(range);
    if(range==='High'){
        return '5';
    }else if (range==='Mod High'){
        return '4';
    }else if (range==='Mod'){
        return '3';
    }else if (range==='Mod Low'){
        return '2';
    }else if (range==='Low'){
        return '1';
    }else{
        return '5';
    }
}