import React, { Component } from 'react';
import './TooltipSchw.css';

class TooltipSchw extends Component {
    render() {
        // Render nothing if the "show" prop is false
        if (!this.props.showTooltip) {
            return null;
        }

        return (
            <div className={'tooltip'} style={{ top: this.props.y - 62, left: this.props.x - 16 }}>
                <div className={'tooltip-content-container-schw'}>
                    <img className={'icon-chart-indicator-schw'} src={require('.././img/IMX-Chart-Indicator.svg')} alt="Loading" />
                    <div className={'icon-chart-hover-schw'}><div className={'txt-tooltip-schw'}><strong>{this.props.dataPointValue}</strong></div></div>
                </div>

            </div>
        );
    }
}


// Tooltip.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     show: PropTypes.bool,
//     children: PropTypes.node
// };

export default TooltipSchw;
