import React from 'react';
import './SimpleShare.css';
//Todo Check on social link address and add email modal and ask if still want popups
export default function SimpleShare(props) {

    if(!props.isShareOpen){
        return null;
    }

    return (
        <div className={'simple-share-container'}>

            <div className="simple-share-header-container">

                <div className="simple-share-header-text float-l"><strong>Share with your friends</strong></div>

                <div className={'simple-share-close'} onClick={props.shareToggle}>
                    <div className="simple-share-close-btn float-r"><div className={'img-close-dialog icon-16'}></div></div>
                </div>


            </div>

            <div className={'simple-share-flex-container'}>

                <div className="simple-share-icon" provider="facebook">
                    <a href="javascript:window.open('https://www.facebook.com/dialog/share?app_id=64368537493&href=http%3a%2f%2ffw.to%2funuHOcF&display=popup&redirect_uri=https://socialize.gigya.com/GS/bookmark.aspx?close=true','mywindow','width=600,height=600');"
                    //    target="_blank"
                       onClick={props.shareToggle}
                    >
                       <div>
                           <div className={'img-facebook-color icon-16'}></div>
                           <span><strong>Facebook</strong></span>
                       </div>

                    </a>
                </div>

                <div className="simple-share-icon" provider="facebook" onClick={() => {props.friendsToggle(); props.shareToggle();}}>
                    <div className={'img-email-color icon-16'}></div><span><strong>Email</strong></span>
                </div>

                <div className="simple-share-icon" provider="twitter">
                    <a href="javascript:window.open('https://twitter.com/share?url=http:%2F%2Ffw.to%2FyHdhfSU&text=Learn%20more%20about%20TD%20Ameritrade%E2%80%99s%20Investor%20Movement%20Index%20(IMX)%2C%20which%20indicates%20the%20sentiment%20of%20retail%20investors','mywindow','width=600,height=600');"
                    //    target="_blank"
                       onClick={props.shareToggle}
                    >
                        <div>
                            <div className={'img-twitter-color icon-16'}></div>
                            <span><strong>Twitter</strong></span>
                        </div>

                    </a>
                </div>

                <div className="simple-share-icon" provider="linkedIn">
                    <a href="javascript:window.open('https://www.linkedin.com/start/join?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fsharing%2Fshare-offsite%3Furl%3Dhttp%3A%252F%252Ffw.to%252FqFoauRO%26mini%3Dtrue%26title%3DTD%2520Ameritrade%2527s%2520Investor%2520Movement%2520Index%26ro%3Dfalse%26summary%3DLearn%2520more%2520about%2520TD%2520Ameritrade%25E2%2580%2599s%2520Investor%2520Movement%2520Index%2520%28IMX%29%252C%2520which%2520indicates%2520the%2520sentiment%2520of%2520retail%2520investors.%26source%3D&trk=login_reg_redirect','mywindow','width=600,height=600');"
                    //    target="_blank"
                       onClick={props.shareToggle}
                    >

                        <div>
                            <div className={'img-linkedin-color icon-16'}></div>
                            <span><strong>LinkedIn</strong></span>
                        </div>

                    </a>
                </div>

            </div>

        </div>
    );
}


