import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

    static defaultProps = {
        type: 'tab1'
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label,
        };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const isSchwab = window.location.pathname.indexOf('stax') > -1;
        const {
            onClickTabItem,
            props: {
                children,
                label
            },
            state: {
                activeTab,
            }
        } = this;

        return (
            <div id={'tabs-'+label} className={'tabs'}>
                <ol className={`${isSchwab ? "tab-list-schw" : "tab-list"}`} role='tablist'>
                    {children.map((child) => {
                        const { label } = child.props;

                        return (
                            <Tab
                                iconClass={child.props.iconClass}
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </ol>
                <div id={'tab-content'} className="tab-content">
                    {children.map((child) => {
                        if (child.props.label !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;