import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/./App';
// import registerServiceWorker from './registerServiceWorker';
import qp from "./utils/queryParams";
import Home from './containers/Home';

let cName = qp("c_name");
cName = cName ? cName : sessionStorage.getItem("cName");
if (cName) {
    sessionStorage.setItem("cName", cName);
}


ReactDOM.render(<Home />, document.getElementById('imx-app'));
//registerServiceWorker();

