import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonLoader = (props) => {
    const uiColorCoreGreen300 = window.location.pathname.indexOf('stax') > -1 ? '#037dae' : '#40a829';
    return (
        <div className={props.className || ''}>
            <SkeletonTheme highlightColor={uiColorCoreGreen300}>
                {props.children
                    ? props.children
                    : <Skeleton
                        count={5}
                        height={8}
                        duration={5} />
                }
            </SkeletonTheme>
        </div>
    );
};

export default SkeletonLoader;

