const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthShort = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// FormatMonthDate
// import {formatMonthDate} from './utilities';

 export function formatMonthDate(dateStr,format){
    let parts = dateStr.split("/")


    switch(format) {
        case 'ms':
            return (monthShort[parts[0] - 1]);
        case 'mm-yyyy':
            return (month[parts[0] - 1] + " " + parts[2]);
        case 'dd-mm':
            return (month[parts[0] - 1] + " " + parts[1]);
        case 'mmddyyyy':
            return (parts[0]+parts[1]+parts[2]);
        case 'yy':
            return (parts[2].substring(2, 4));
        case 'yyyy':
            return (parts[2]);
        default:
            return (month[parts[0] - 1] + " " + parts[2])
    }

};



