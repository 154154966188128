import React, { Component } from 'react';
import './ReadingIMXSchw.css';
import Tippy from '@tippy.js/react';
import 'tippy.js/themes/light-border.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';


class ReadingIMXSchw extends Component {



    render() {
        const dayData = this.props.readingData;
        if (!dayData.trendDirection) {
            return null;
        }
        const trendColor = (dayData.trendDirection === "Negative") ? "trend-down" : "trend-up-schw";
        const arrowDirection = (dayData.trendDirection === "Negative") ? false : true;
        const myRange = getRange(dayData.scoreRange);
        console.log(dayData.scoreRange);
        const imxScoreContent = `STAX score for month ${this.props.monthDate.toString()} is ${dayData.imxScore} with ${dayData.trendDirection} ${dayData.imxScoreChange.toFixed(2)}`

        return (

            <div id={"reading-imx-container-schw"} className={"reading-imx-container-schw bm-20"}>

                <Tippy
                    content={<div className={"tooltip-main-container"}><div role={"tooltip"} tabIndex={0}><strong>Reading STAX</strong></div><div>The most recent value of the STAX (a proprietary measure of investor portfolio sentiment).</div></div>}
                    placement="bottom"
                    arrow={true}
                    followCursor={true}
                    theme="light-border"
                    distance={25}
                >
                    <div className={"txt-Helvetica24-schw border-bottom-02 bm-25"} role={"tooltip"} >Reading (STAX)</div>
                </Tippy>

                <div className={"bm-20"}><strong>{this.props.monthDate.toString()}</strong></div>

                <div className={"reading-center bm-20 reading-content"} tabIndex={0} aria-label={imxScoreContent}>
                    <div className={"txt-Helvetica32-schw imx-score float-l"}>{dayData.imxScore}</div>
                    {arrowDirection ? <FontAwesomeIcon icon={faCircleArrowUp} color={"#037dae"} fontSize={"24px"} width={"30px"} /> :
                        <FontAwesomeIcon icon={faCircleArrowDown} color={"#037dae"} fontSize={"24px"} width={"30px"} />}

                    <div className={"reading-center txt-Helvetica24-schw float-l " + trendColor}>{dayData.imxScoreChange.toFixed(2)} ({(trendColor === "trend-down") ? null : "+"}{dayData.imxScoreChangePercent.toFixed(2)}%)</div>
                </div>

                <div className={"border-bottom bm-20 cb"}></div>
                <div>

                </div>
                <Tippy
                    content={<div className={"tooltip-main-container"}><div><strong>Historic Range</strong></div><div role={"tooltip"}>The latest month's score as compared to the historical score range.</div></div>}
                    placement="bottom"
                    arrow={true}
                    followCursor={true}
                    theme="light-border"
                    distance={25}
                >
                    <div className={"bm-20"} role={"tooltip"}><strong>Historic range (since Jan 2019 inception)</strong></div>
                </Tippy>
                <Tippy
                    content={<div className={"tooltip-main-container"}><div><strong>Schwab Trading Activity Index</strong></div><div>Historic Range</div>
                        <br /><div><strong>JANUARY 2019 - PRESENT</strong></div><br />
                        <div style={{ display: "flex" }}><div style={{ width: "50%" }}><div>Low</div><div><strong>{this.props.minDataPoint.imxScore}</strong></div><div>{this.props.minDataPoint.date}</div></div>
                            <div><div>High</div><div><strong>{this.props.maxDataPoint.imxScore}</strong></div><div>{this.props.maxDataPoint.date}</div></div></div></div>}
                    placement="bottom"
                    arrow={true}
                    followCursor={true}
                    theme="light-border"
                    distance={40}
                >
                    <div className={"bm-20"} role={"tooltip"}><img name={"progress-bar"} className={"progress-bar-schw"} src={require('.././img/ProgressBarSchw' + myRange + '.png')} alt="progress bar" /></div>
                </Tippy>

                <div className={"txt-Helvetica24-schw border-bottom-02 bm-10"}>Trends</div>
                <Tippy
                    content={<div className={"tooltip-main-container"}><div><strong>Trend Direction</strong></div><div>The trend direction shows whether the month over month change in score is positive or negative, or whether there's no change (neutral).</div></div>}
                    placement="bottom"
                    arrow={true}
                    followCursor={true}
                    theme="light-border"
                    distance={25}
                >
                    <div className={"txt-Helvetica12-schw border-bottom-reading bm-10 direction-content"}>
                        <div role={"tooltip"}><strong>Direction</strong></div>
                        <div className={"direction-arrow"}>
                            {arrowDirection ? <FontAwesomeIcon icon={faCircleArrowUp} color={"#037dae"} fontSize={"24px"} width={"30px"} /> :
                                <FontAwesomeIcon icon={faCircleArrowDown} color={"#037dae"} fontSize={"24px"} width={"30px"} />}
                        
                        <div className={'float-r ' + trendColor} style={{paddingTop: "4px"}}>
                            {dayData.trendDirection}
                        </div>
                        </div>
                    </div>
                </Tippy>
                <Tippy
                    content={<div className={"tooltip-main-container"}><div><strong>Trend Length</strong></div><div>The number of consecutive months the trend direction has been positive or negative.</div></div>}
                    placement="bottom"
                    arrow={true}
                    followCursor={true}
                    theme="light-border"
                    distance={25}
                >
                    <div className={"txt-Helvetica12-schw bm-10 cb"} role={"tooltip"}><strong>Length</strong></div>
                </Tippy>

                <div className={"trend-container"} tabIndex={0}>
                    <div className={"txt-Helvetica12-schw bm-10"}><strong>Previous trend: </strong>{dayData.previousTrendDirection} ({dayData.previousTrendLength} month{(dayData.previousTrendLength > 1) ? 's' : null})</div>
                    <div className={"txt-Helvetica12-schw bm-10"}><strong>Current trend: </strong>{dayData.trendDirection} ({dayData.trendLength} month{(dayData.trendLength > 1) ? 's' : null})</div>
                </div>


            </div>
        );
    }
}

export default ReadingIMXSchw;


const getRange = (range) => {
    console.log(range);
    if (range === 'High') {
        return '5';
    } else if (range === 'Mod High') {
        return '4';
    } else if (range === 'Mod') {
        return '3';
    } else if (range === 'Mod Low') {
        return '2';
    } else if (range === 'Low') {
        return '1';
    } else {
        return '5';
    }
}
