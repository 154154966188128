import React, { Component } from 'react';
import {formatMonthDate} from './utilities';
import './ChartTableSchw.css';

class ChartTableSchw extends Component {

    // componentDidMount(){
    //     this.props.handleChange(0);
    //
    // }

    handleSend = (obj) => {
        if(obj.hasSummary) {
            this.props.handleChange(obj.val, "chart table");
        }
    }


    render() {
        //console.log(this.props.monthDate);
        const template = this.props.yearData.reverse().map((obj,index) =>{
            let hasUnderline=(obj.hasSummary)?("underline"):null;
            let allowHover=(obj.hasSummary)?("row-allow-hover-schw"):null;
            let hasVideo=(obj.hasVideo)?<div className={'img-video-grey icon-chart-table'}></div>:null;

            let itemActive = (formatMonthDate(obj.date,'mm-yyyy')===this.props.monthDate)?' item-active-schw':'';

            return(
                <div
                    className={'row-info row-container ' + allowHover + itemActive }
                    key={'month-'+index}
                    tabIndex={0}
                    data-date={formatMonthDate(obj.date,'mm-yyyy')}
                    onKeyDown={e => e.key === 'Enter' ? this.handleSend(obj) : ''}
                    onClick={ () => (obj.hasSummary)?this.props.handleChange(obj.val, "chart table"):null }
                >
                    
                    <div className={'item-container item-left'}>{hasVideo}<strong>{(obj.date==='---')?obj.date:formatMonthDate(obj.date,'dd-mm')}</strong></div>
                    <div className={'item-container item-center'}>{obj.spxScore}</div>
                    <div className={'item-container item-right  ' + hasUnderline}>{obj.imxScore}</div>
                </div>
            );
        })



        return (
            <div id={'year-container-'+this.props.year} className={'year-container'}>

                <div className={'row-container'}>
                    <div className={'item-container item-left'}><strong>Date</strong></div>
                    <div className={'item-container item-center'}><strong>S&P Index</strong></div>
                    <div className={'item-container item-right'}><strong>STAX</strong></div>
                </div>

                <div className={'row-container'}>
                    <div className={'item-container item-left'}><strong>{this.props.year}</strong></div>
                </div>

                {template}
            </div>
        );
    }
}


export default ChartTableSchw;